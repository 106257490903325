import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <p>
          Hello this is a bare-bones application. This has been edited ... again and again.
        </p>
      </header>
    </div>
  );
}

export default App;
